<template>
  <!-- 联系我们 -->
  <div id="contactUs">
    <div class="pageCont container tl">
      <bread class="nav" :title="title" :subtitle="subtitle"></bread>
      <el-divider></el-divider>
      <div class="titleBox" :style="{ background:'url('+imgpath+')'}">
        <div class="title">
          <p class="text-28 text-white margin-bottom-xs">{{titleName}}</p>
          <el-divider></el-divider>
          <p class="title-cont text-16 text-white margin-top-xs">{{titleinfo}}</p>
        </div>
      </div>
      <div class="detailBox">
        <p class="text-28 text-black margin-xs padding-sm tc">{{jgjszn}}</p>
        <div class="padding-sm" v-html="info"></div>
      </div>
      <div class="aboutBox">
        <div class="aboutInfo fl">
          <p class="text-28 text-white text-bold" style="margin: 70px 0 20px;">联系我们</p>
          <div>
            <ul>
              <li v-for="(item,index) in list" :key="index">
                <p class="name text-16">{{item.name}}</p>
                <p class="text-16 margin-bottom-sm">{{item.info}}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="aboutMap fl">
          <el-amap vid="amapDemo" :zoom="zoom" :center="center" style="height: 500px;">
            <el-amap-marker
              v-for="marker in markers"
              :position="marker.position"
              :events="marker.events"
              :key="marker.index"
            ></el-amap-marker>
            <el-amap-info-window
              v-for="window in windows"
              :offset="window.offset"
              :position="window.position"
              :content="window.content"
              :open="window.open"
              :key="window.index"
            ></el-amap-info-window>
          </el-amap>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import toHead from "@/components/Header.vue";
import bread from "@/components/breadcrumb.vue";

export default {
  data() {
    return {
      title: "联系我们",
      subtitle: "",
      titleName: "",
      titleinfo: "",
      jgjszn: "",
      info: "",
      imgpath: "",
      list: [
        {
          name: "电话号码：",
          info: ""
        },
        {
          name: "邮箱地址：",
          info: ""
        },
        {
          name: "联系地址：",
          info: ""
        }
      ],

      zoom: 16,
      center: [113.20062, 33.76721],
      markers: [],
      windows: [],

      unit: "",
      address: ""

      // center: [113.20062, 33.76721],
      // zoom: 15,
      // label: {
      //   // content: "自定义标题",
      //   offset: [10, 12]
      // }
    };
  },

  mounted() {
    // this.initMap();
    this.getData();
    this.initRichText();
  },

  methods: {
    // 获取数据
    getData() {
      this.apis.api.getContactUsDatas().then(res => {
        if (res.data.code == "01") {
          let datas = res.data.dat;

          datas.forEach(e => {
            if (e.keyy == "title") {
              this.titleName = e.valuess;
            }
            if (e.keyy == "fileId") {
              this.imgpath = "/pub/img/" + e.pathxname;
              // this.imgpath = "http://192.168.1.249:8084/pub/img/" + e.pathxname;
            }
            if (e.keyy == "titleinfo") {
              this.titleinfo = e.valuess;
            }
            if (e.keyy == "jgjszn") {
              this.jgjszn = e.uname;
            }
            if (e.keyy == "phone") {
              this.list[0].info = e.valuess;
            }
            if (e.keyy == "email") {
              this.list[1].info = e.valuess;
            }
            if (e.keyy == "address") {
              this.list[2].info = e.valuess;
            }

            if (e.keyy == "unit") {
              this.unit = e.valuess;
            }

            if (e.keyy == "address") {
              this.address = e.valuess;
            }

            if (e.keyy == "xy") {
              //   // json字符串转数值型数组
              var coord = e.valuess.split(",");
              this.center = coord.map(Number);
              this.markers.push({
                position: coord.map(Number)
              });

              this.windows.push({
                position: coord.map(Number),
                content:
                  "<h2 style='font-weight: bold;width: 400px;margin: 10px'>" +
                  this.unit +
                  "</h2>" +
                  "<div style='margin: 10px'>" +
                  "地址：" +
                  this.address +
                  "</div>",
                offset: [0, -20],
                open: true
              });
            }
          });
        }
      });
    },

    // 初始化富文本
    initRichText() {
      this.apis.api.getInitBrief().then(res => {
        if (res.data.code == "01") {
          let datas = res.data.dat;
          // console.log(datas, "富文本**");
          this.info = datas && datas.info;
        }
      });
    },

    // 初始化地图
    initMap(map) {
      this.markers.push({
        position: [113.20062, 33.76721]
      });

      this.windows.push({
        position: [113.20062, 33.76721],
        content:
          "<h2 style='font-weight: bold;width: 400px;margin: 10px'>" +
          this.unit +
          "</h2>" +
          "<div style='margin: 10px'>" +
          "地址：" +
          this.address +
          "</div>",
        offset: [0, -20],
        open: true
      });

      // var map = new AMap.Map("container", {
      //   zoomEnable: true, //是否禁止缩放
      //   zoom: 12, //缩放比例
      //   dragEnable: false, //禁止拖动
      //   cursor: "hand" // 鼠标在地图上的表现形式，小手
      // });
      // // 初始化工具条
      // map.plugin(["AMap.ToolBar"], function() {
      //   map.addControl(new AMap.ToolBar());
      // });
    }
  },
  components: {
    toHead,
    bread
  }
};
</script>
<style lang="scss" scoped>
#contactUs {
  .pageCont {
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    .titleBox {
      width: 100%;
      height: 400px;
      // background: url("~@/assets/images/bg-6.png") no-repeat;
      .title {
        position: relative;
        padding: 20px;
        bottom: -269px;
        width: 100%;
        height: 130px;
        background-color: rgba(0, 0, 0, 0.5);
        .title-cont {
          width: 1120px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    .detailBox {
      width: 100%;
      height: 300px;
    }
    .aboutBox {
      width: 100%;
      height: 500px;
      .aboutInfo {
        width: 300px;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
        color: #fff;
        background-color: #000;
        .name {
          color: #02a7f0;
          line-height: 50px;
        }
      }
      .aboutMap {
        width: 860px;
        height: 500px;
      }
    }
  }
  // 分割线
  /deep/.el-divider--horizontal {
    margin: 0 0 10px;
  }
}
</style>

